import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";

const BusinessMentoringSussexPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				cale: file(relativePath: { eq: "testimonials/cale-sinfield.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Business Mentoring in Sussex";
	const description = "";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const cale = data.cale.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url:`${siteUrl}`,
					id:`${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business Mentoring",
				item: {
					url:`${siteUrl}/business-mentoring`,
					id:`${siteUrl}/business-mentoring`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Sussex",
				item: {
					url:`${siteUrl}/business-mentoring/sussex`,
					id:`${siteUrl}/business-mentoring/sussex`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/business-mentoring/sussex`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business Mentoring in Sussex",
						},
					],
				}}
			/>

			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Business Mentor Sussex"
				description1="Business mentoring in Sussex for self-employed small business owners"
				description2=""
			/>

			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Looking for a business mentor in Sussex?</h2>
							<p>
								I’m Jason Cornes, and as an experienced business mentor, I can
								help you to:
							</p>
							<ul className="pl-4">
								<li>
									Put your vision in place and devise a plan to get you there
								</li>
								<li>
									Help you to develop your sales and marketing and find new
									clients
								</li>
								<li>Boost your businesses profitability</li>
								<li>Scale or grow your business</li>
								<li>And see more cash in the bank.</li>
							</ul>
							<p>
								If you aren’t 100% happy in your business right now or if you
								feel key things are holding you back from getting you to where
								you want to be in life, small business mentoring will help you
								enormously.
							</p>
							<p>
								Whether you find yourself bursting with enthusiasm but not
								achieving the results you deserve or feeling fearful but not
								sure why. Perhaps you’re lacking self-confidence and persuading
								yourself why something won’t work before you actually try. Maybe
								it’s time to think and act differently to get different results?
							</p>
							<p>
								Alternatively, it could be a challenge with the way you run your
								business, anything from cash flow to customer service,
								profitability to marketing - as your business mentor I will help
								and support you to overcome the hurdles that are getting in the
								way of your progress.
							</p>
							<p>
								I’ve mentored numerous self-employed small businesses owners
								across Sussex to do just this, helping them to identify the
								areas for improvement and develop an action-packed plan to make
								effective and long lasting changes.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5">
				<Container>
					<Row>
						<Col>
							<h2 className="py-3">Running a business in Sussex.</h2>
							<p>
								In my humble opinion, Sussex is one of the most beautiful places
								in the country, and for good reason. We have stunning natural
								assets like Camber in the East, the Severn Sisters in the South
								and the wonderful South Downs National Park.
							</p>
							<p>
								These natural assets and many more are why hundreds of thousands
								of visitors flock to our region each year, and it’s a beautiful
								area from which to operate as a small business.
							</p>
							<p>
								And just like our region, you have your own set of natural
								assets and talents, those things that flow naturally for you,
								your core set of skills, your processes or intellectual
								property.
							</p>
							<p>
								As a business mentor, it’s my job to help you enhance and
								leverage these natural talents while minimising and overcoming
								any areas where you may not feel in your element that might be
								holding you back. To look for opportunities that you may not be
								acting upon.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Like a supporting business partner.</h2>
							<p>
								My role as your business mentor isn’t about prescribing you
								things you should or shouldn’t do. Instead, I act like a
								supporting business partner on your side throughout our coaching
								period, observing, listening and offering unbiased advice based
								on my own years of experience for the myriad of decisions you
								need to make to grow your business.
							</p>
							<p>
								Whether this coaching is delivered remotely or face-to-face,
								business mentoring will cover:
							</p>
							<ul className="pl-4">
								<li>Listening</li>
								<li>Getting strategic</li>
								<li>Changing your reality</li>
								<li>The money thing</li>
								<li>Agreed action points and holding you accountable</li>
							</ul>
							<p>
								A business strategy/MOT focus will create action plans covering:
							</p>
							<ul className="pl-4">
								<li>Leadership and business skills</li>
								<li>Marketing</li>
								<li>Sales</li>
								<li>Financial</li>
								<li>Operational</li>
							</ul>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								A case study from a business here in Sussex
							</h2>
							<p>
								Take Cale who owns and runs two businesses. Social Bee Social
								Media and Best of Hastings. Both of Cale’s businesses are
								thriving and he retains me ongoing as his hidden business
								partner. Cale explains everything in his local testimonial
								below:
							</p>
						</Col>
					</Row>
					<HorizontalTestimonial
						name="Cale Sinfield. Social Bee Social Media"
						review="I have two successful businesses which are both taking off and I felt
                      pulled in two directions, difficulty focusing on the growth of both,
                      prioritising and using my time to maximum effect. I was also trying to
                      'be strong' and do everything myself when it was clear that I needed
                      to delegate or outsource but I was unsure how. With these challenges
                      in mind, I approached Jason to ask for his free discovery meeting to
                      explain my situation and discuss his 'Hidden Business Partner'
                      services. Having met with Jason it was obvious to me that he was
                      someone I could trust and has excellent listening skills and
                      considerable small business experience. I had spoken with some of his
                      many existing clients at business networking events and read his
                      online reviews and so decided to engage his services. Jason helps me
                      to understand my personality better, why I prefer to do things in my
                      business in the way that I do, highlighting my strengths and building
                      my self-confidence. How to gain the strategic direction and support I
                      need to move forward with my businesses. Jason is insightful and has
                      the ability to look at a situation from many different angles. If I am
                      feeling stuck he makes original suggestions, motivating me and
                      creating momentum. In each and every session we agree on action points
                      and he keeps me on track and holds me accountable. I suggest you meet
                      Jason for a coffee. You won't regret it. It's not a quick fix, it is a
                      journey together, so stick with it and he will help you to change your
                      thoughts, behaviours and feelings and get the results in your small
                      business you are looking for"
						profilePic={cale}
						linkedIn="https://www.linkedin.com/in/calesinfield/"
					/>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Ready to grow your Sussex business in 2021?
							</h2>
							<p>
								Whether it’s been accountants or consultants, teachers or
								tradespeople, creatives, coaches or academics - I’ve helped
								small business owners and individuals across Sussex grow, become
								more confident and achieve what they want to do in life.
							</p>
							<p>
								Whether it’s face-to-face (I’m based in Pevensey near
								Eastbourne), or remotely via Zoom or Skype - I’d love to help
								you do the same with some quality bespoke business mentoring.
							</p>
							<p>
								To make the changes you want to your business, speak to me about
								some expert small business mentoring here in Sussex. Click below
								to book an introductory call with me.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Business Mentoring in Sussex"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default BusinessMentoringSussexPage;
